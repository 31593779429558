import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function IndividualMonuments() {
  const data = useStaticQuery(graphql`
    query IndividualMonumentsQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "individual" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Individual Monuments"
      title="Individual Monuments Gallery | Falls Monument | (920) 467-4939"
      description="Photo gallery of individual monuments that we have made for customers."
      urlPath="individual-monuments"
    />
  );
}
